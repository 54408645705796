import './template.min.css'
import './nav.min.css'
import { gsap } from 'gsap'

const titleContainer = document.querySelector('.title-container')
const backgroundImage = document.querySelector('.background-image')

const navbar = document.querySelector('.navbar')
const navOptions = document.querySelectorAll('.navbar li')
const navLinks = document.querySelectorAll('.navbar li a')
const navLinkDashes = document.querySelectorAll('.navbar li .dash')
const navButton = document.querySelector('.nav-button')
const navBurger = document.querySelectorAll('.nav-button .line')
const navRibbons = [
    document.querySelector('.nav-bg .ribbon-1'),
    document.querySelector('.nav-bg .ribbon-2'),
    document.querySelector('.nav-bg .ribbon-3'),
]

const donateButton = document.querySelector('.donate-button')
const donateCircle = document.querySelector('.donate-circle')
const coinContainer = document.querySelector('.donate-button .coin-container')
const coinRotate = document.querySelector('.donate-button .coin-rotate')
const boxContainer = document.querySelector('.donate-button .box-container')
const donateBox = document.querySelector('.donate-button .box')
const scrollIndicator = document.querySelector('.scroll-indicator')

// Variables
let navOpen = true
let blurred = false
let prevMouseTween = null
let userScrolled = false

// Prevent saving the scroll position
if (history.scrollRestoration) {
    history.scrollRestoration = 'manual'
}

/* -------- START NAV -------- */

// Set initial values for future animations
// Sets transform origin for donate icon so it scales up nicely
// and for the arrow components so they fold down neatly into a single line
gsap.set([navButton, donateCircle, coinContainer, coinRotate, boxContainer], {
    transformOrigin: (index) => {
        if (index === 0) {
            return '15% 15%'
        }
        if (index === 1) {
            return '85% 15%'
        }
        if (index === 2) {
            return '50% 25%'
        }
        if (index === 3) {
            return '50% 50%'
        }
        if (index === 4) {
            return '50% -50%'
        }
    },
})
// Center the donate icon
gsap.set([coinRotate, donateBox], {
    xPercent: -50,
    x: 0,
})
// Center the burger icon and save initial values with gsap
gsap.set(navBurger, {
    xPercent: -50,
    yPercent: -50,
    x: 0,
    y: 0,
    scale: 0.99,
})
// Translate nav options right for style
gsap.set(navOptions, {
    x: (index) => {
        return 2.5 * index + 'vw'
    },
    transformOrigin: '0 100%',
})
// Scale the dashes to 0 for nav link hover animation
gsap.set(navLinkDashes, {
    transformOrigin: '100% 0',
    scaleX: 0,
})

// Force a reload to prevent BFcache
window.addEventListener('pageshow', (e) => {
    if (e.persisted) {
        location.reload()
    }
})
// Enable menu button
navButton.style.pointerEvents = 'all'
// Enable donate button
donateButton.style.pointerEvents = 'all'

// Menu button hover effect and click listener
navButton.addEventListener('mouseenter', growNavButton)
navButton.addEventListener('focus', growNavButton)
navButton.addEventListener('mouseleave', shrinkNavButton)
navButton.addEventListener('focusout', shrinkNavButton)
navButton.addEventListener('click', toggleNav)
navButton.addEventListener('keyup', (e) => {
    if (e.keyCode === 13) {
        navButton.click()
    }
})

// Hover effect for menu button
function growNavButton() {
    gsap.to([navButton, navBurger], {
        scale: (index) => {
            return index === 0 ? 1.4 : 0.8
        },
        duration: 1,
        ease: 'power2.out',
    })

    // Don't animate xPercent on hover if navbar open (otherwise it interferes with navAnimation timeline)
    if (navOpen) return

    gsap.to(navBurger, {
        xPercent: (index) => {
            return 20 * index - 70
        },
        duration: 0.5,
        ease: 'circ.in',
    })
}
function shrinkNavButton() {
    gsap.to([navButton, navBurger], {
        scale: 0.99,
        duration: 1,
        ease: 'power2.out',
    })

    // Don't animate xPercent on hover if navbar open (otherwise it interferes with navAnimation timeline)
    if (navOpen) return

    gsap.to(navBurger, {
        xPercent: -50,
        duration: 0.5,
        ease: 'circ.in',
    })
}

// Timeline for navbar animation on click
const navAnimation = new gsap.timeline({
    paused: true,
})
// Burger to X animation on click
const burgerAnimation = navAnimation.to(navBurger, {
    xPercent: -50,
    yPercent: (index) => {
        return -185 * index + 185
    },
    rotation: (index) => {
        return index === 0 ? -400 : 400
    },
    opacity: (index) => {
        return index === 1 ? 0 : 1
    },
    duration: 1,
    ease: 'power4.inOut',
    onStart: () => {
        navOpen = true
        // Prevent clicking on the page
        navbar.style.pointerEvents = 'all'
        // Hide scrollbar
        document.body.style.overflowY = 'hidden'
    },
    onComplete: () => {
        shrinkNavButton()
    },
    onReverseComplete: () => {
        navOpen = false
        // Prevent navbar from blocking pointer events on the page
        navbar.style.pointerEvents = 'none'
        // Show scrollbar
        document.body.style.overflowY = 'auto'
        shrinkNavButton()
    },
})
// Ribbon background animation on click
navAnimation.to(
    navRibbons,
    {
        xPercent: 0,
        yPercent: 0,
        duration: 0.25,
        stagger: 0.18,
    },
    '<0.2'
)
// Nav text elements animation on click
navAnimation.to(
    navOptions,
    {
        autoAlpha: 1,
        duration: 0.3,
        stagger: 0.05,
    },
    '<0.3'
)

// Ribbon transition when clicking internal link
let transitionLinks = document.querySelectorAll('.ribbon-transition')
for (let link of transitionLinks) {
    link.addEventListener('click', showNavRibbons)
}
function showNavRibbons(e = null) {
    let currentTarget = null
    if (e) {
        e.preventDefault()
        currentTarget = e.currentTarget
    }

    let langTransition = false
    if (e && currentTarget.classList.contains('lang-button')) {
        document.querySelector('.lang-button.selected').classList.remove('selected')
        currentTarget.classList.add('selected')
        langTransition = true
    }

    gsap.to(navRibbons, {
        xPercent: 0,
        yPercent: 0,
        duration: 0.25,
        stagger: 0.18,
        onComplete: () => {
            if (!e) return

            if (langTransition) {
                location.href = location.origin + '/' + currentTarget.textContent.toLowerCase() + location.pathname
                return
            }

            location.href = currentTarget.href
        },
    })
}

// Precalculate values
navAnimation.progress(1, true).progress(0, true)

function toggleNav() {
    if (!navOpen) {
        // Reset start values and play animation
        burgerAnimation.invalidate()
        navAnimation.restart()
        return
    }
    navAnimation.reverse()
}

// Donate button hover effect and click listener
donateButton.addEventListener('mouseenter', growDonate)
donateButton.addEventListener('focus', growDonate)
donateButton.addEventListener('mouseleave', shrinkDonate)
donateButton.addEventListener('focusout', shrinkDonate)
donateButton.addEventListener('click', donateTransition)

// Hover effect for donate button
function growDonate() {
    gsap.to([donateCircle, coinContainer, boxContainer], {
        scale: (index) => {
            return index === 0 ? 1.4 : 1.2
        },
        duration: 1,
        ease: 'power2.out',
    })
}
function shrinkDonate() {
    gsap.to([donateCircle, coinContainer, boxContainer], {
        scale: 0.99,
        duration: 1,
        ease: 'power2.out',
    })
}

// When user clicks donate button
function donateTransition(e) {
    e.preventDefault()
    donateButton.removeEventListener('mouseleave', shrinkDonate)
    donateButton.removeEventListener('focusout', shrinkDonate)

    showNavRibbons()

    // Timeline for transition
    const donate = new gsap.timeline()
    donate.to([donateCircle, coinContainer, boxContainer], {
        scale: (index) => {
            return index === 0 ? 6 : 1.4
        },
        duration: 0.5,
        ease: 'power3.inOut',
        overwrite: true,
    })
    donate.to(
        coinRotate,
        {
            yPercent: 150,
            rotation: -180,
            duration: 1,
            ease: 'back.in(3.5)',
        },
        '>-0.3'
    )
    donate.to(
        donateButton,
        {
            autoAlpha: 0,
            duration: 0.2,
            onComplete: () => {
                location.href = donateButton.href
            },
        },
        '>-0.1'
    )
}

// Hover effect for nav links
navLinks.forEach((option, index) => {
    option.index = index
    // Check if user on same page as nav link
    if (
        location.pathname === option.pathname ||
        location.pathname === `${option.pathname}/` ||
        location.pathname === `${option.pathname}/index.html`
    ) {
        option.isCurrent = true
        gsap.set(navLinkDashes[option.index], {
            scaleX: 1,
        })
    } else {
        option.isCurrent = false
    }
    option.addEventListener('mouseenter', animateLink)
    option.addEventListener('mouseleave', reverseLink)
    option.addEventListener('click', linkTransition)
})

// Nav link hover effect
function animateLink() {
    // this = currently hovered nav link
    gsap.to(navOptions[this.index], {
        scale: 1.04,
        duration: 0.3,
    })
    // Only animate the dash on links that the user is not currently on
    if (!this.isCurrent) {
        gsap.to(navLinkDashes[this.index], {
            scaleX: 1,
            duration: 0.3,
            ease: 'circ.out',
        })
    }
}
function reverseLink() {
    // this = currently hovered nav link
    gsap.to(navOptions[this.index], {
        scale: 1,
        duration: 0.3,
    })
    // Only animate the dash on links that the user is not currently on
    if (!this.isCurrent) {
        gsap.to(navLinkDashes[this.index], {
            scaleX: 0,
            duration: 0.3,
            ease: 'circ.in',
        })
    }
}

function linkTransition(e) {
    e.preventDefault()

    if (this.index === 0) {
        // Fade to black if user clicked on home
        gsap.to('.transition-cover', {
            opacity: 1,
            duration: 0.4,
            ease: 'power1.in',
        })
    }
    // Fade out and scale up clicked option
    gsap.to(navOptions[this.index], {
        scale: 3,
        opacity: 0,
        duration: 0.2,
    })
    // Fade out and translate down other options
    gsap.to(navOptions, {
        y: 20,
        opacity: 0,
        delay: 0.1,
        stagger: 0.05,
        duration: 0.2,
    })
    // Return menu icon to unopened position
    gsap.to(navBurger, {
        xPercent: -50,
        yPercent: -50,
        rotation: 0,
        opacity: 1,
        duration: 0.5,
        onComplete: () => {
            location.href = this.href
        },
    })
}

/* -------- END NAV -------- */

// Get the values of the background image position
const xPercent = parseInt(backgroundImage.style.left)
const yPercent = parseInt(backgroundImage.dataset.y)
// Save the values in GSAP
gsap.set(backgroundImage, {
    xPercent: -xPercent,
    yPercent: 0,
    x: 0,
    y: 0,
    transformOrigin: `${xPercent}% ${yPercent}%`,
})

// Fade out title on scroll
const titleTween = gsap.to(titleContainer, {
    opacity: 0.001,
    pointerEvents: 'none',
    ease: 'power1.in',
    duration: 0.5,
    paused: true,
})
titleTween.progress(1)
titleTween.progress(0)
// Blur background on scroll
const blurTween = gsap.to(backgroundImage, {
    filter: 'blur(16px)',
    ease: 'none',
    duration: 0.5,
    overwrite: true,
    paused: true,
})
blurTween.progress(1)
blurTween.progress(0)

window.addEventListener('scroll', runTitleAnimation)
function runTitleAnimation() {
    let rect = titleContainer.getBoundingClientRect()
    // If title container is scrolled 10% of the viewport height
    if (rect.bottom < 0.8 * (window.innerHeight || document.documentElement.clientHeight)) {
        if (blurred) return
        // Stop image zoom on mousemove animation
        if (prevMouseTween) {
            prevMouseTween.kill()
        }
        titleTween.play()
        blurTween.play()
        blurred = true
    } else {
        if (!blurred) return
        titleTween.reverse()
        blurTween.reverse()
        blurred = false
    }
}

// Zoom in on mouse move (lower = more zoomed in)
document.addEventListener('mousemove', (e) => {
    // If image is blurred, do nothing
    if (blurred) return

    let deltaY = e.y / window.innerHeight
    if (prevMouseTween) {
        prevMouseTween.kill()
    }
    prevMouseTween = gsap.to(backgroundImage, {
        scale: 1 + deltaY / 4,
        duration: 2,
    })
})

// Prevent scrolling when keyboard shows up on mobile
window.addEventListener('resize', () => {
    if (document.activeElement.tagName.toLowerCase() != 'input') return

    // Get active element (focused) and check if in view
    let rect = document.activeElement.getBoundingClientRect()
    if (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
        return

    // If not in view, scroll into view
    const absoluteElementTop = rect.top + window.scrollY
    const middle = absoluteElementTop - window.innerHeight / 4
    window.scrollTo(0, middle)
})

window.onload = () => {
    // Translate ribbons off-screen
    gsap.to(navRibbons, {
        xPercent: -40,
        yPercent: (index) => {
            return index === 1 ? -200 : 200
        },
        duration: 0.25,
        stagger: -0.18,
    })
    navOpen = false
    navbar.style.pointerEvents = 'none'
    document.body.style.overflowY = 'auto'

    // After 5 seconds of inactivity, show the scroll indicator
    setTimeout(() => {
        // Don't display the indicator if user has scrolled
        if (userScrolled) return
        // Don't display the indicator if there is no content
        if (!document.querySelector('.content-container')) return
        scrollIndicator.style.opacity = 1
    }, 2000)
    document.addEventListener(
        'scroll',
        () => {
            userScrolled = true
            // Fade out indicator
            scrollIndicator.style.opacity = 0
        },
        { once: true }
    )
}

// Success and error banners
export function showBanner(message, messageFR, type = 1, duration = 5, onClick = null) {
    let banner = document.createElement('div')
    banner.classList.add('banner')
    let color = '#02a95c'
    if (type === 0) color = '#ed1b2f'
    else if (type === 2) color = '#996eff'
    banner.style.backgroundColor = color
    banner.innerHTML = document.documentElement.lang == 'fr' ? messageFR : message
    document.body.appendChild(banner)

    if (onClick) {
        banner.addEventListener('click', onClick)
        banner.style.pointerEvents = 'all'
        banner.style.cursor = 'pointer'
    }

    gsap.set(banner, {
        xPercent: -50,
        y: '-105%',
    })

    gsap.to(banner, {
        y: '2vh',
        duration: 0.4,
        ease: 'power3.out',
    })
    gsap.to(banner, {
        y: '-105%',
        duration: 0.2,
        delay: duration,
        ease: 'power3.out',
        onComplete: () => {
            banner.removeEventListener('click', onClick)
            banner.remove()
        },
    })
}

function redirectToNewWebsite() {
    location.href = document.documentElement.lang == 'fr' ? 'https://fondationcoda.ca/' : 'https://codafoundation.ca/'
}
showBanner(
    'We have a new website, click here to visit it!',
    'Nous avons un nouveau site Web, cliquez ici pour le visiter!',
    1,
    10,
    redirectToNewWebsite
)

// Signature
console.log(
    '%c \u2727 David\u00A0Lu \u2727 ',
    `
    text-align: center;
    font-family: Brush Script MT, Brush Script, Brush Script MT Italic, sans-serif;
    font-weight: bold;
    font-size: 2rem;
    color: #ffffff;
    background: #996eff;
    text-shadow: 2px 2px 2px #00000080;
    `
)
